import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'

export const TabTotem = styled.div`
  padding: 30px;
  @media (max-width: 768px){
    padding: 15px;
  }
  .react-tabs__tab-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #b6b8dc;
    padding: 8px 0px;
    .react-tabs__tab {
      color: #001e62;
      text-align: center;
      font-family: 'ASICSFont3.0-Regular';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .react-tabs__tab--selected {
      color: #001e62;
      text-align: center;
      font-family: 'ASICSFont3.0-Regular';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      background: transparent;
      border: none;
    }
    .react-tabs__tab:focus:after {
      border-radius: 15px;
      background: #001e62;
      bottom: -10px;
    }
    .react-tabs__tab--selected:after {
      content: '';
      position: absolute;
      height: 5px;
      left: -4px;
      right: -4px;
      border-radius: 15px;
      background: #001e62;
      bottom: -10px;
    }
  }
  .react-tabs__tab-panel--selected {
    padding-top: 10px;
    .banner-quick-scan {
      width: 100%;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }
`

export const ListProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 10px;
  gap: 2rem;
  height: 550px;
  overflow-y: scroll;
  @media (max-width: 768px){
    gap: 15px;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 30px;
    border: 1px solid #001e62;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #001e62;
  }
`
export const Products = styled.div`
  flex: 0 0 auto;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  justify-content: space-between;
  @media (max-width: 768px){
    width: 48%;
    padding-bottom: 15px;
  }
  img {
    width: 100%;
  }
  .botao-comprar {
    display: flex;
    justify-content: center;
  }
  .box-img-tab{
    display: flex;
    justify-content: center;
  }
`

export const TitleProduct = styled.p`
  color: #001e62;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`

export const TypeProduct = styled.p`
  color: #001e62;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

export const PriceProduct = styled.div`
  display: flex;
  gap: 10px;
  color: #001e62;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  span {
    font-weight: 400;
    text-decoration: line-through;
  }
  p {
    font-weight: 700;
  }
`

export const NavLink = styled(Link).attrs(({ element }) => ({
  as: element,
}))`
  border-radius: 47.6047px;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  padding: 8px 30px;
  border-radius: 30px;
  background: #001e62;
  width: 90%;
  &:hover {
    border-radius: 30px;
    background: #424FB8;
    color: white;
    transition: background 0.4s;
  }
`
