import styled from 'styled-components'


export const QuickScanTotem = styled.div`
  background-color: white;
  width: 100%;
  padding-bottom: 30px;
  margin-top: -80px;
  padding-right: 16px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 102vh;
  @media (max-width: 768px){
    padding-right: 0;
  }

  .title {
    padding: 150px 60px 0 60px;
    text-align: center;
    padding-bottom: 30px;
    h1 {
      color: #001e62;
      font-style: italic;
      font-weight: 700;
      font-size: 30px;
      line-height: 32px;
      font-family: 'ASICSFont3.0-Bold', sans-serif;
    }
  }

`
