import React from 'react'
import PropTypes from 'prop-types'

const Option = ({ children, value, ...props }) => (
  <option {...props} value={value}>
    {children}
  </option>
)

Option.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
}

export default Option
