import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'


export const BtnVoltar = styled.div`
    position: fixed;
    bottom: 25px;
    right: 36px;
    border-radius: 7.969px;
    background: #FFF;
    width: 141.053px;
    height: 45.424px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    p{
        color: #001E62;
        text-align: center;
        font-size: 21.732px;
        font-style: italic;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }
`