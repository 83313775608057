import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Input = ({ type, id, placeholder, isCumulus23 = false, ...props }) => {
  return (
    <S.Input
      type={type}
      id={id}
      name={id}
      placeholder={placeholder}
      isCumulus23={isCumulus23}
      {...props}
    />
  )
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isCumulus23: PropTypes.bool,
}

export default Input
