import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Select = ({ children, id, isCumulus23 = false, ...props }) => (
  <S.SelectContainer>
    <S.Select id={id} name={id} isCumulus23={isCumulus23} {...props}>
      {children}
    </S.Select>
  </S.SelectContainer>
)

Select.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isCumulus23: PropTypes.bool,
}

export default Select
