import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { ditoVerifica } from '../../../helpers/dito'
import { GlobalStorage } from '../../../Context/global'
import { ComparadorStorage } from '../../../Context/comparador'
import { QuizCorridaStorage } from '../../../Context/quizCorrida'
import { QuizCorredorStorage } from '../../../Context/quizCorredor'

import GlobalStyle from '../../../GlobalStyles'

import 'react-toastify/dist/ReactToastify.css'
import 'swiper/swiper-bundle.min.css'
import 'react-awesome-lightbox/build/style.css'
import '../../../assets/style/font-face.css'
import NavbarTotem from '../navbarTotem'

const Layout = ({ children, loja }) => {
  useEffect(() => {
    ditoVerifica()
  }, [])

  return (
    <GlobalStorage>
      <ComparadorStorage>
        <QuizCorridaStorage>
          <QuizCorredorStorage>
            <main>
              <NavbarTotem loja={loja} />
              <GlobalStyle />
              {children}
            </main>
          </QuizCorredorStorage>
        </QuizCorridaStorage>
      </ComparadorStorage>
    </GlobalStorage>
  )
}

export default Layout

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}
