import styled from 'styled-components'

export const Label = styled.label`
  color: var(--primary-color);
  display: block;
  font-family: 'ASICSFont3.0-BoldItalic';
  font-size: 0.875rem;
  text-transform: uppercase;
  margin: 0 0 0.313rem 1.25rem;
`

export const Input = styled.input`
  background-color: #fff;
  border: 1px solid;
  border-color: ${({ isLP }) => (isLP ? '#5467cb' : '#fff')};
  border-radius: 1.875rem;
  color: var(--primary-color);
  font-weight: bold;
  font-style: italic;
  font-size: 12px;
  height: 3.125rem;
  padding: 0 20px;
  transition: border 0.3s ease;
  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid var(--primary-color);
  }

  &::placeholder {
    color: var(--thirdy-color);
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
    text-transform: uppercase;
  }

  &:invalid {
    border: 1px solid red;
  }

  &.erro {
    border: 1px solid red !important;
  }
`

export const Select = styled.select`
  background-color: #fff;
  border: 1px solid;
  border-color: ${({ isLP }) => (isLP ? '#5467cb' : '#fff')};
  border-radius: 1.875rem;
  color: var(--primary-color);
  font-weight: bold;
  font-style: italic;
  font-size: 12px;
  height: 3.125rem;
  padding: 0 25px;
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
    border: 1px solid var(--thirdy-color);
  }

  &.erro {
    border: 1px solid red !important;
  }

  &::-ms-expand {
    display: none;
  }
`

export const Radio = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      padding-left: 1.875rem;
      cursor: pointer;
      line-height: 1.25rem;
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 1.125rem;
        height: 1.125rem;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
      }

      &::after {
        content: '';
        width: 0.75rem;
        height: 0.75rem;
        background: var(--primary-color);
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  &:not(:checked) {
    & + label {
      &::after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
    }
  }

  &:checked {
    & + label {
      &:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
`

export const Checkbox = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;

    & + label {
      color: var(--primary-color);
      cursor: pointer;
      display: inline-block;
      font-size: 0.9rem;
      font-style: italic;
      line-height: 1.25rem;
      padding-left: 1.875rem;
      position: relative;

      a {
        color: var(--primary-color);
      }

      &::before {
        content: '';
        position: absolute;
        left: -8px;
        top: -8px;
        width: 24px;
        height: 24px;
        border: 1px solid #ddd;
        background: #fff;
      }

      &::after {
        content: '';
        width: 16px;
        height: 16px;
        background: var(--primary-color);
        position: absolute;
        top: -3px;
        left: -3px;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }
  }

  &:not(:checked) {
    & + label {
      &::after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
    }
  }

  &:checked {
    & + label {
      &:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
`

export const SelectContainer = styled.div`
  position: relative;
  display: inline;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 0.3em;
    right: 0.75em;
    border-top: 8px solid var(--primary-color);
    //opacity: 0.5;
  }
`
