import React, { useState } from 'react';
import * as S from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Select from '../../Form/select';
import Option from '../../Form/option';
import data from '../../../data/quickScan'
import Input from '../../../components/Form/input'
import and from 'and';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch'

const CompreporCategoriaTotem = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const {
    allProduto: { edges: allProduto },
  } = useStaticQuery(graphql`
    query {
      allProduto(
        filter: {
          alternative_id: { ne: 0 }
          lojas: { elemMatch: { slug: { eq: "ecom" } } }
          pais: { eq: "br" }
        }
        sort: { fields: nome }
      ) {
        edges {
          node {
            ...produtoCampos
            
            localFoto {
              childImageSharp {
                gatsbyImageData(
                  width: 199
                  height: 107
                  transformOptions: { cropFocus: CENTER }
                  layout: FIXED
                  placeholder: BLURRED
                  quality: 70
                )
              }
            }
            foto
            pais
            lojas {
              slug
              precoLista
              precoVenda
            }
          }
        }
      }
    }
  `);

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    if (value === "default") {
      setSelectedCategory('');
    } else {
      setSelectedCategory(value);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <S.CompreporCategoriaTotem>
      <S.Filtros>
        <S.SearchModeloWrapper className='search'>
          <FaSearch />
          <S.InputSearch
            type="text"
            id="search"
            name="search_modelo"
            placeholder="Buscar produto..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </S.SearchModeloWrapper>
        <div className='divselect'>
          <Select id="categoria-select" placeholder="Buscar produto..." onChange={handleCategoryChange}>
            <option value="default">Todas as categorias</option>
            {data.categoria.map((categoria, k) => (
              <Option key={`select-categoria-${k}`} value={categoria.indicadoPara}>
                {categoria.indicadoPara}
              </Option>
            ))}
          </Select>
        </div>
      </S.Filtros>
      <S.ListProducts>
        {allProduto
          .filter(({ node: produto }) => {
            if (searchTerm !== '') {
              return produto.nome.toLowerCase().includes(searchTerm.toLowerCase());
            }
            return true;
          })
          .filter(({ node: produto }) => {
            if (selectedCategory && selectedCategory !== '') {
              return produto.indicadoPara.includes(selectedCategory);
            }
            return true;
          })
          .map(({ node: produto }) => {
            const { precoLista, precoVenda } = produto.lojas.find(
              (v) => v.slug === 'ecom'
            );
            return (
              <S.Products key={produto.id}>
                <div className='box-img-tab'>
                  <img src={produto.foto} alt={produto.nome} srcSet={produto.foto} />
                  <GatsbyImage
                    className="banner-quick-scan"
                    alt={produto.nome}
                    image={getImage(produto.localFoto)}
                  />
                </div>
                <S.TitleProduct>{produto.nome}</S.TitleProduct>
                <S.TypeProduct>
                  {and(produto.indicadoPara, 'e')}
                </S.TypeProduct>
                <S.PriceProduct>
                  {precoLista && <span>{precoLista}</span>}
                  {precoVenda && <p>{precoVenda}</p>}
                </S.PriceProduct>
                <S.NavLink
                  element={'a'}
                  href={produto.urlComprar + "?utm_source=totem"}
                  target="_blank"
                >
                  COMPRAR
                </S.NavLink>
              </S.Products>
            );
          })}
      </S.ListProducts>
    </S.CompreporCategoriaTotem>
  );
};

export default CompreporCategoriaTotem;
