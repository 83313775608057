export default {
  categoria: [
    {
      indicadoPara: 'academia',
    },
    {
      indicadoPara: 'Casual',
    },
    {
      indicadoPara: 'Corrida',
    },
    {
      indicadoPara: 'Tennis',
    },
    {
      indicadoPara: 'Trilha',
    },
    {
      indicadoPara: 'Vôlei',
    },
  ],
}
