import React, { useState, useEffect} from 'react'
import * as S from './styles'
import { navigate } from "gatsby";

const BtnVoltar = ({ }) => {

  return (
    <S.BtnVoltar onClick={() => navigate(-1)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="23" viewBox="0 0 33 23" fill="none">
            <rect x="1.43304" y="10.5396" width="30.6749" height="2.39648" rx="1.19824" fill="#001E62"/>
            <rect x="0.953613" y="11.7988" width="15.3374" height="2.39648" rx="1.19824" transform="rotate(-45 0.953613 11.7988)" fill="#001E62"/>
            <rect width="15.3374" height="2.39648" rx="1.19824" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.953613 11.7549)" fill="#001E62"/>
        </svg>
        <p>voltar</p>
    </S.BtnVoltar>
  )
}



export default BtnVoltar
