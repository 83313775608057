import styled from 'styled-components'
import { Link } from 'gatsby-plugin-react-i18next'

export const CompreporCategoriaTotem = styled.div`
  padding: 30px;
  @media (max-width: 768px){
    padding: 15px;
  }
  
`

export const Filtros = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  input{
    border-radius: 50.388px;
    border: 1px solid #001E62;
    background: #FFF;
  }
  .divselect{
    width: 50%;
    select{
      border-radius: 50.388px;
      border: 1px solid #001E62;
      text-transform:  uppercase;
    }
    div::after {
      content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        top: 0px;
        right: 1em;
        padding: 6px;
        border: solid transparent;
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
        box-shadow: 2px -2px 0 1px var(--primary-color) inset;
      }
  }

`



export const SearchModeloWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;

  svg {
    color: #001A57;
    font-size: 20px;
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 768px){
      right: 20px;
    }
  }
  input{
    border-radius: 50.388px;
    border: 1px solid #001E62;
    background: #FFF;
    padding: 15px 10px 15px 30px;
    transition: border 0.3s ease;
    width: 100%;

    &:focus {
      outline: none;
    }

    @media (max-width: 700px) {
      width: 90%;
    }

    &::placeholder {
      color: #001A57;
    }
  }
`

export const InputSearch = styled.input`
  border-radius: 30px;
  border: 1px solid #B6B8DC;
  padding: 5px 10px 5px 30px;
  transition: border 0.3s ease;
  width: 100%;

  &:focus {
    outline: none;
  }

  @media (max-width: 700px) {
    width: 90%;
  }

  &::placeholder {
    color: #001A57;
  }
`



export const ListProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 10px;
  gap: 2rem;
  height: 550px;
  overflow-y: scroll;
  @media (max-width: 768px){
    gap: 15px;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 30px;
    border: 1px solid #001e62;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #001e62;
  }
`
export const Products = styled.div`
  flex: 0 0 auto;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  justify-content: space-between;
  @media (max-width: 768px){
    width: 48%;
    padding-bottom: 15px;
  }
  img {
    width: 100%;
  }
  .botao-comprar {
    display: flex;
    justify-content: center;
  }
  .box-img-tab{
    display: flex;
    justify-content: center;
  }
`

export const TitleProduct = styled.p`
  color: #001e62;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`

export const TypeProduct = styled.p`
  color: #001e62;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
`

export const PriceProduct = styled.div`
  display: flex;
  gap: 10px;
  color: #001e62;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  span {
    font-weight: 400;
    text-decoration: line-through;
  }
  p {
    font-weight: 700;
  }
`

export const NavLink = styled(Link).attrs(({ element }) => ({
  as: element,
}))`
  border-radius: 47.6047px;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  padding: 8px 30px;
  border-radius: 30px;
  background: #001e62;
  width: 90%;
  &:hover {
    border-radius: 30px;
    background: #424FB8;
    color: white;
    transition: background 0.4s;
  }
`
