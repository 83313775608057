import React, { useEffect } from 'react'
import * as S from '../quick-scan/styles'
import { graphql } from 'gatsby'
import Layout from '../../../components/totem/layout'
import TabsQuickScanTotem from '../../../components/totem/Tab'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'
import CompreporCategoriaTotem from '../../../components/totem/CompreCategoria'


const QuickScanTotem = ({ pageContext: { loja } }) => {
  
  useEffect(() => {
    ditoTrack('acessou-quick-scan')
  }, [])

  return (
    <Layout loja={loja}>
        <S.QuickScanTotem>
            <div className="title">
                <h1>
                  ENCONTRE SEU ASICS IDEAL
                </h1>
            </div>
            <div className='w-full'>
              <CompreporCategoriaTotem/>
            </div>
        </S.QuickScanTotem>
        <BtnVoltar/>
   </Layout>
  )
}

QuickScanTotem.propTypes = {
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}


export default QuickScanTotem

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
